var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          staticClass: "searchWrapper",
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
              return _vm.submitData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "right",
                "label-width": "150px",
                model: _vm.formInline,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "text-align": "right", width: "100%" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "88px", "margin-bottom": "20px" },
                      attrs: { type: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v("返回")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #C0CCDA",
                    "border-radius": "4px",
                    padding: "15px",
                  },
                },
                [
                  _c("h2", { staticClass: "title" }, [
                    _c("div", { staticClass: "title_icon" }),
                    _c("span", [_vm._v("活动详情")]),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { width: "900px" } },
                    [
                      _c("el-form-item", { attrs: { label: "活动名称:" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.formInline.couponActivityName)),
                        ]),
                      ]),
                      _c("el-form-item", { attrs: { label: "活动时间:" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.formInline.start))]),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "关联优惠券", prop: "couponRuleIds" },
                        },
                        [
                          _vm._l(
                            _vm.formInline.couponRuleActivities,
                            function (item) {
                              return _c("p", { key: item.couponRuleId }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.couponName) +
                                    " " +
                                    _vm._s(item.publishAmount) +
                                    "张"
                                ),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "领取规则:", prop: "oneDayTimes" } },
                        [
                          [
                            _c("p", [
                              _vm._v(
                                "活动期间每人每日可领取" +
                                  _vm._s(_vm.formInline.onePersonOneDayTimes) +
                                  "次"
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                "活动期间每人总共可领取" +
                                  _vm._s(_vm.formInline.onePersonTotalTimes) +
                                  "次"
                              ),
                            ]),
                          ],
                        ],
                        2
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "发行量:", prop: "publishAmount" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formInline.publishAmount)),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "领取量:", prop: "receiveAmount" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formInline.receiveAmount)),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "使用量:", prop: "usedAmount" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formInline.usedAmount)),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动状态:", prop: "status" } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("statusFilter")(_vm.formInline.status)
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "审核状态:", prop: "auditStatus" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formInline.auditStatus)),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建人:", prop: "creatorName" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formInline.creatorName)),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建时间:", prop: "createdTime" } },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.formInline.createdTime)),
                          ]),
                        ]
                      ),
                      _vm.isauditor
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "审核人:",
                                    prop: "auditorName",
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(_vm.auditorName))])]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "审核时间:",
                                    prop: "auditTime",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.formInline.auditTime)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.unloadUser
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "下架人:",
                                    prop: "unloadUserName",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.unloadUserName)),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "下架时间:",
                                    prop: "unloadTime",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.formInline.unloadTime)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }